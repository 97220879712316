import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";


import "./Tabs.css";

import rockIcon from '../assets/skylandheader128.png';


class Tabs extends Component {
 

  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };

    this.logoUri = this.props.logoUri;
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  render() {
    const {
      onClickTabItem,
      openInNewTab,
      props: { children },
      state: { activeTab },
      logoUri: { logoUri }
    } = this;

    return (
      <div className="tabs">
        <ol className="tab-list">
          <li className="title-item">
            <a href={this.logoUri} target="_blank" rel="noreferrer noopener">
              <img alt="Brand" src={rockIcon}          />
            </a>
          </li>
          {children.map((child) => {
            const { label } = child.props;

            if (child.props.enable === true)
              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                />
              );
            else {
              console.log("nav: disabled ",child.props.label,activeTab);
              // if disabled but active
              if (child.props.label == activeTab) {
                console.log("nav: disabled ",child.props.label," -> switched to ",child.props.alternate);
                onClickTabItem(child.props.alternate);
              }
              return "";
            }
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
