import React, { useState } from 'react';
import classes from './Navigation.module.css';


import rockIcon from '../assets/skylandheader128.png';

import Tabs from "./Tabs";



const Item = ( props ) => {
  return "";
}


const Navigation = (props) => {
  const [poolStatus, setPoolStatus] = useState('default');

  //console.log("##NAV##",props);


  const changeModul = (val) => {
    if (val === poolStatus) {
    } else {
      setPoolStatus(val);
//      props.changePage();
    }
  };

  //  console.log("nav: enabled:",props.enabled, poolStatus);

   if ( !props.enabled[0] && poolStatus == 'default') {
    changeModul(2);
   }
/*
<Item label='UnRock' name='unrock' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
  {props.children[4]}
</Item>
*/

  
  return (
    <div>
      <Tabs className={classes.navigation} logoUri='https://www.skyland-villas.com/'>
          <Item label='Coin' alternate='Dig' enable={props.enabled[0]} >
            {props.children[0]}
          </Item>
          <Item label='Dig' name='dig' enable={props.enabled[1]} poolStatus={poolStatus} changeModul={changeModul}>
            {props.children[1]}
          </Item>
          <Item label='Order' name='order' enable={props.enabled[2]} poolStatus={poolStatus} changeModul={changeModul}>
            {/*857268 && window.ether.selectedAddress ? */  props.children[9] /*: "connect your wallet" */}
          </Item>
          <Item label='Card' name='order' enable={props.enabled[3]} poolStatus={poolStatus} changeModul={changeModul}>
            {/*857268 && window.ether.selectedAddress ? */  props.children[10] /*: "connect your wallet" */}
          </Item>
          <Item label='*' name='info' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
            {window.active /*&& window.ether.selectedAddress*/ ?  props.children[7] : "connect your wallet" }
          </Item>
        </Tabs>
      </div>
  )


};

/*
          <Item label='Claim' name='claim' enable={props.enabled[6]} poolStatus={poolStatus} changeModul={changeModul}>
            {props.children[6]}
          </Item>

          <Item label='Vault' name='rock' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[2]}
        </Item>
        <Item label='Tx' name='tx' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[3]}
        </Item>
        <Item label='Earn' name='payout' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[5]}
        </Item>

        */
       
export default Navigation;
