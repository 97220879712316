import React from 'react';
import classes from './Profile.module.css';

import { xBalance } from '../X';

import { xUSDZAddress,xUSDTAddress } from "../X";


import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';


import StripeCheckoutForm  from './StripeCheckoutForm';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

//const stripePromise = loadStripe('pk_test_f3duw0VsAEM2TJFMtWQ90QAT');

const stripePromise = loadStripe('pk_live_51MtthsIalLCCiBFhXo7a5Xo1iHfwskRvDGczwhRBYXmXZGISYlUP1LL2cL2hemP9NSh7qXtfAKQXZ506ATpWgwsV00KrbVcISS');


const Stripe = (props) => {

    const nano = (p) =>  {      return Math.round(p * 10**(9-18));    }

//    const milliEth = (p) => {      return Math.round(p * 10**(3-props.priceDecimals));    }

    const rc = (p,d,c) => { let a = p / 10**d; let i = Math.floor(a); let f = a-i; return ( i.toLocaleString('en-US') + f.toFixed(c).toString().substring(1) )  }
    const rcRock   = (p) =>      {      return  rc( p, props.info.decimals,3).replace(/0+$/,"").replace(/\.$/,"");    }

    const rcPrice  = (p) =>      {      return  rc( p, props.priceDecimals,2);    }
    const rcPrice6 = (p) =>      {      return  rc( p, props.priceDecimals,6);    }


    const options = {
      // passing the client secret obtained from the server
      clientSecret: 'price_1Mug27IalLCCiBFh5CrRkiwP',
    };
  
    return (
    <div className={classes.profile}>
      <div className={classes.header}>
        <p><br/></p>
      </div>
      <div className={classes.info} width="200">
        <Elements stripe={stripePromise} options={options}>
          <StripeCheckoutForm />
        </Elements>
      </div>
    </div>
  );
};

export default Stripe;
