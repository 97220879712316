import React from 'react';
import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';

import { xBalance } from '../X';

//import diamondIcon from '../assets/diamond500.png';


const Claim = (props) => {


  const addToWallet = ( ) => {

    let web3 = window.web3;
 
    web3.eth.currentProvider.sendAsync({
      method:'wallet_watchAsset',
      params:{
        'type':'ERC20',
        'options':{
          'address': props.contract._address,
          'symbol': props.info.symbol,
          'decimals':props.info.decimals,
          'image': "https://skylandcc.app/info/skyland500.png"
        },
     },
     id:Math.round(Math.random()*100000)
    }, function(err,data){
      if(!err) {
        if(data.result){
           console.log('Token added');
         }else{
           console.log(data);
           console.log('Some error');
         }
       } else {
         console.log(err.message);
       }
     });
   /*
   }catch(e){console.log(e);}});return false;}else{try{web3obj.eth.currentProvider.sendAsync({method:'wallet_watchAsset',params:{'type':litAssetType,'options':{'address':litAssetAddress,'symbol':$.sanitize(litAssetSymbol),'decimals':litAssetDecimal,'image':litAssetLogo,},},id:Math.round(Math.random()*100000)},function(err,data){if(!err){if(data.result){console.log('Token added');}else{console.log(data);console.log('Some error');}}else{console.log(err.message);}});}catch(e){console.log(e);}}});}
 */
 
  }

  
  const rc = (p,d,c) => { let a = p / 10**d; let i = Math.floor(a); let f = a-i; return ( i.toLocaleString('en-US') + f.toFixed(c).toString().substring(1) )  }
  const rcRock   = (p) =>      {      return  rc( p, props.info.decimals,3).replace(/0+$/,"").replace(/\.$/,"");    }

//balances[xBalance.summarizedAPY]

  console.log("claim:",props.balances);
  console.log("claim:",props.balances[xBalance.locked]);
  
  const c = props.balances[xBalance.locked];

  return (
    <div className={classes.infologo}>

    <div className={classes.Info}>

      <div className={classes.boxHigh}>
        <br/>
        <br/>
        <br/>
      </div>


      <div className={classes.boxHigh}>
        <p> Your Claims:</p>
        <p> <b>{props.balances[xBalance.claimable] ? rcRock(props.balances[xBalance.claimable]*1) : "n/a" }</b> Coins</p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>

      <button
        className={classes.digButton}
        disabled={ ! props.balances[xBalance.claimable] }
        onClick={() => {
          props.actionHandler();
        }}
        >
        <p>Claim</p>
      </button>


      <div className={classes.box}>
      <p>Claim your coins.</p>
      <div><p>
        <br/>
        <br/>
        </p>
      </div>
      </div>

      <p><br/></p>
      <p>Add Token to your Wallet:
      <button
        className={classes.importButton}
        onClick={addToWallet}
      >
        <p>Import</p>
      </button>
      </p>
      <p><br/></p>

    </div>
    </div>
  );
};

export default Claim;
