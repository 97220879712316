
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';


Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

const defaultStyles = {
  clockStyle: {
    height: '8rem',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    fontSize: '50px',
    fontFamily: 'sans-serif',
    letterSpacing: '5px',
    textShadow: '0 0 10px #fff',
    textTransform: 'uppercase'
  },
  clockHeaderStyle: {
    margin: '13px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  clockSubHeader: {
    marginBottom: '10px',
    fontSize: '13px',
    letterSpacing: 'initial'
  }
};

class Clock extends Component {

  static propTypes = {
    startDate: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
    clockShadow: PropTypes.string,
    day: PropTypes.bool,
    clockDigitStyle: PropTypes.string,
    clockSeparator: PropTypes.string
  };

  state = {
    dateTimestamp: Date.now()
  };


  componentDidMount() {
    this.interval = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () => {
    this.setState({ dateTimestamp: Date.now() });
  }

/*
  calculateNumberOfDaysLeft = startDate => {
    return moment(startDate).diff(Date.now(), 'days');
  }
*/
  // pad string with zero
  pad = (str, max = 2) => str.length < max ? `0 ${str}` : str;

  static Day = ({ diffDate, isDayEnabled }) => {
    return <div>
      {
        isDayEnabled ?
          <div style={{ ...defaultStyles.clockHeaderStyle }}>
            <div style={{ ...defaultStyles.clockSubHeader }}> {diffDate ? 'DAYS' : 'DAY'} </div>
            <div>{diffDate.day.pad(2)}</div>
          </div> :
          ''
      }
    </div>;
  }

  static Seperator = ({ clockSeparator, shouldShow = true }) => {
    return <span>{shouldShow ? (clockSeparator ? clockSeparator : '.') : ''}</span>;
  }

  static Hour = ({ diffDate }) => {
    return <div style={{ ...defaultStyles.clockHeaderStyle }}>
      <div style={{ ...defaultStyles.clockSubHeader }}> hours </div>
      <div>{diffDate.hour.pad(2)}</div>
    </div>;
  }
  static Minutes = ({ diffDate }) => {
    return <div style={{ ...defaultStyles.clockHeaderStyle }}>
      <div style={{ ...defaultStyles.clockSubHeader }}> minutes </div>
      <div>{diffDate.minute.pad(2)}</div>
    </div>
  }

  static Seconds = ({ diffDate }) => {
    return <div style={{ ...defaultStyles.clockHeaderStyle }}>
      <div style={{ ...defaultStyles.clockSubHeader }}> seconds </div>
      <div>{diffDate.second.pad(2)}</div>
    </div>
  }



  render() {
    const { startDate, day, style, clockSeparator } = this.props;
//    const daysLeft = this.pad(this.calculateNumberOfDaysLeft(startDate).toString());
    const isDayEnabled = day === undefined ? false : day;



    let interval = startDate - Date.now()/1000;

    const days = Math.floor( interval / (24*3600) );
    //console.log("clock: days",interval,days)
    interval -= days*24*3600;

    const hours = Math.floor( interval / 3600);
    //console.log("clock: hours:",interval,hours)
    interval -= hours*3600;

    const minutes = Math.floor( interval / 60);
    //console.log("clock: mins:",interval,minutes)
    interval -= minutes*60;

    const seconds = Math.floor(interval);
    
    const diffDate = { day: days, hour: hours, minute: minutes, second: seconds};
    //console.log("clock:",diffDate);


    return (
      <div
        style={{
          ...defaultStyles.clockStyle,
          ...style
        }}>
        <Clock.Day diffDate={diffDate} isDayEnabled={isDayEnabled} />
        <Clock.Seperator clockSeparator={clockSeparator} shouldShow={isDayEnabled} />
        <Clock.Hour diffDate={diffDate} />
        <Clock.Seperator clockSeparator={clockSeparator}  />
        <Clock.Minutes diffDate={diffDate} />
        <Clock.Seperator clockSeparator={clockSeparator}  />
        <Clock.Seconds diffDate={diffDate} />
      </div>
    );
  }
}

export default Clock;