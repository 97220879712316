import React from "react";

export default (props) => (
<>
<div class="contract-section">
    <h2>§ 1 Subject of the Contract</h2>
    <p>1.1 The seller agrees to sell {props.count} Commodity-Coins to the buyer at a total price of USD {props.price}.</p>
    <p>1.2 The Commodity-Coins are linked to the following commodities: {props.description}.</p>
    <p>1.3 Furthermore, SkyLand CC have a fixed and unchangeable exchange rate for SkyLand SQARES Tokens of 2000:1.</p>
</div>

<div class="contract-section">
    <h2>§ 2 Purchase Price and Payment</h2>
    <p>2.1 The purchase price is USD {props.price} and includes all taxes and fees.</p>
    <p>2.2 Payment shall be made by bank transfer to the seller's account or when accepted by the seller, in a
comparable form. The account / payment details will be communicated to the Buyer.</p>
    <p>2.3 The purchase price must be transferred within {props.days} business days after the contract is concluded.</p>
</div>

<div class="contract-section">
    <h2>§ 3 Delivery of Commodity-Coins</h2>
    <p>3.1 The delivery of the Commodity-Coins shall be made within {props.days} business days after receipt of the full purchase price in the seller's account.</p>
    <p>3.2 The seller agrees to send the buyer a confirmation of the transfer of the Commodity-Coins by email.</p>
    <p>3.3 {props.wallet.length>20 ? 
        <>
        The Buyers Wallet Address is the following Ethereum Address: {props.wallet}.
        </>
        : 
        <>
         The Buyer hereby orders the delivery of SkyLand CC to a Wallet Address which he will communicate to the
Seller after signing this purchase agreement. The Buyer is advised to copy - paste the Wallet address here
to avoid errors in transcribing. If the present contract is not returned to the Seller in digital form, the Buyer
is requested to necessarily provide the Wallet Address separately, in digital form, copy-pasteable, for
example, in the text of the email in addition. The seller copies the specified wallet address without checking
it again on correctness by himself. A typing of the wallet address by the seller, e.g. from a purchase contract
in paper form or scanned or photographed documents without the possibility of direct text copying, is
excluded.
        </>
        }
    </p>
</div>

<div class="contract-section">
    <h2>§ 4 Warranty and Liability</h2>
    <p>4.1 The seller warrants that the Commodity-Coins are free from third-party rights.</p>
    <p>4.2 The seller is only liable for damages caused by intent or gross negligence. Liability for slight negligence is excluded.</p>
</div>

<div class="contract-section">
    <h2>§ 5 Right of Withdrawal</h2>
    <p>5.1 The Buyer hereby expressly waives their right to withdraw from this purchase agreement or to revoke it.
This waiver also applies to all other statutory rights of withdrawal or revocation that the Buyer may be
entitled to under the law of their place of residence or other applicable legal orders. The parties agree that
this contract can be concluded by electronic signature and that an electronic signature has the same legal
effect as a handwritten signature. By electronically signing this contract, the Buyer confirms that they have
read and understood all the terms of this contract and waive their right of withdrawal or revocation. The
Buyer confirms that this contract was concluded following a personal meeting and direct negotiation
between the parties.</p>
</div>

<div class="contract-section">
    <h2>§ 6 Data Protection</h2>
    <p>6.1 The seller agrees to use the buyer's personal data only for the purpose of processing the contract and not to disclose it to third parties.</p>
</div>

<div class="contract-section">
    <h2>§ 7 Final Provisions</h2>
    <p>7.1 Amendments and additions to this contract must be made in writing.</p>
    <p>7.2 Should any provision of this contract be invalid, the remainder of the contract shall remain effective. The invalid provision shall be replaced by a valid provision that most closely approximates the economic intent of the invalid provision.</p>
    <p>7.3 The law of the United Arab Emirates applies. The place of jurisdiction is {props.jurisdiction}.</p>
</div>

</>

);

export const Disclaimer = () => {

   return (
        <>
        <h2>Notices, Obligations, and Confirmations</h2>
        <p>

        The buyer is aware that many questions regarding the legal and tax aspects of a token sale, the transfer
and use of tokens, as well as trading in tokens, remain open at this point.
Furthermore, the buyer is aware that purchasing cryptographic commodities involves high risks, and
therefore, buyers should only invest a small portion of their total assets in such high-risk investments to be
able to bear any potential total losses. The buyer confirms that they have been informed about this by the
seller.
The buyer should carefully review and assess all risks and uncertainties associated with fcct, its operations,
the technology, the acquisition rights, and the cryptographic commodities.
The buyer has been advised that cryptographic commodities may be classified as securities in certain
jurisdictions and that the offering and sale of these cryptographic commodities have not been registered
under the securities or similar laws of any jurisdiction and will not be registered, hence they cannot be
resold unless this is done in accordance with the laws of the respective jurisdiction.
Fcct does not provide risk disclosure for the buyer and/or any type of investment advice or brokerage. In
case of doubt, the buyer should consult legal, financial, tax, or other professional advisors. All statements
by fcct about possible future purposes of SkyLand CC are forward-looking and may change or may not
occur due to various circumstances.
It is the buyer's responsibility to ensure that they meet all relevant requirements related to this agreement,
acquire all necessary knowledge, and if necessary, engage their own legal and tax advisors.
The buyer is aware of the following extensive, mentioned, and other risks: General market fluctuations for
cryptographic commodities. Possible additional or new legal restrictions on the possession or trading of
cryptographic commodities. Economic fluctuations affecting trade or generally the world economy.
Additional or new competition with other cryptographic commodities. Potential programming errors or
security vulnerabilities in the smart contract or the blockchain used for issuing a cryptographic commodity
that could be exploited by unauthorized persons.
Limitation of Liability: The liability of fcct or its agents is limited to intent or gross negligence under the
provisions of applicable law. Otherwise, any liability is excluded to the extent legally possible, especially for
indirect damages, consequential damages, or lost profits.
Each party bears its own costs incurred in connection with this agreement (including fees and expenses of
legal advisors, costs related to services of professional advisors, brokers, etc.).
Unless expressly provided or otherwise determined in this agreement (especially in relation to warranties
and guarantees), each party bears the direct and indirect taxes and duties levied by the authorities from
this party.
The parties agree to keep the contents of this agreement confidential and undertake not to disclose any
such information to third parties without the prior express consent of the other party.
Furthermore, the buyer confirms the following: Having significant experience with and understanding of the
use and intricacies of cryptographic commodities and blockchain-based software systems, as well as
knowledge and skills in securely handling cryptographic private keys in an autonomous manner. Having a
functional understanding of storage and transmission mechanisms associated with other cryptographic
commodities.
The buyer confirms with their signature that they acknowledge the aforementioned notices, obligations, and
confirmations.
</p>

        </>
    )
};

