
import React from 'react';

import ProgressBar from "./Progress";
import Clock from './Clock';


const PreSale = (props) => {

    const d = props.preSaleDate;
    const n = new Date()/1000;
  //  if (n > d) { reload()}
   /* console.log("pr dec",props.preSaleDecimals);*/
    
    return (
      <div>
      { d > n ?
        <div>
          <Clock day startDate={d}  ></Clock>
          <ProgressBar
             bgcolor={"#438d96"}
             completed={100 * props.preSaleTotal/ props.preSaleLimit} 
          />
          <br></br>
          STATUS: {props.preSaleTotal / 10**props.preSaleDecimals} / { Math.floor(props.preSaleLimit / 10**props.preSaleDecimals)} RAISED
        </div>
          :
          ""
        }    
        </div>
      );
}

export default PreSale;