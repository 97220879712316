import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 30,
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: '0.5em',
    margin: 1
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
    
  }

  const labelStyles = {
    padding: 5,
    color: 'black',
    fontWeight: 'bold'
  }
  const slabelStyles = {
    padding: 5,
    color: 'black',
    fontWeight: 'bold',
    width: '100%',
    position: 'relative',
    top: '0.3em'
  }

  return (
    <div style={containerStyles}>
      <span style={slabelStyles}> UNTIL PRICE INCREASE </span>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};

export default ProgressBar;
